<template>
  <div >
  <!-- <div v-if="User.role != null" oncontextmenu=" Check() " > -->
    <div :class="isLoading ? 'hidden' : 'block'" class="min-h-screen max-w-full text-gray-800 app-container">
      <Sidebar
        :logout="logout"
        :openSettingsPanel="openSettingsPanel"
        :openAddCoursePanel="openAddCoursePanel"
        :openAddModulePanel="openAddModulePanel"
        :openAddSubscriptionPanel="openAddSubscriptionPanel"
        :startLoading="startLoading"
        :endLoading="endLoading"
        :dark="mode"
        :openCreateSessionModal="openCreateSessionModal"
        :openCreatePlaylistModal="openCreatePlaylistModal"
        :openSelectExamModal="openSelectExamModal"
        :openSelectResidanatExamModal="openSelectResidanatExamModal"
      />
      <main class="main flex flex-col flex-grow -ml-44 sm:ml-0 transition-all duration-150 ease-in">
        <Navbar :logout="logout" :dark="mode" :title="title" />
        <div class="main-content flex flex-col flex-grow mb-2" :class="clicked ? 'sm:ml-20 ml-44' :  'sm:ml-64 ml-44'">
          <div class="flex flex-col flex-grow">

            <div v-if="createSessionDialogVisible">
              <CreateSessionModal :createSessionDialogVisible="createSessionDialogVisible" :closeCreateSessionModal="closeCreateSessionModal" />
            </div>

            <div v-if="createSessionFreeDialogVisible">
              <CreateFreeSessionModal :createSessionFreeDialogVisible="createSessionFreeDialogVisible"  :closeCreateFreeSessionModal="closeCreateFreeSessionModal" />
            </div>

            <div v-if="createPlaylistDialogVisible && Subscribed">
              <AddPlaylistModal :createPlaylistDialogVisible="createPlaylistDialogVisible" :closeCreatePlaylistModal="closeCreatePlaylistModal" />
            </div>

            <div v-if="selectExamModal && (Subscribed || Subscribemodules)">
              <SelectExam :selectExamModal="selectExamModal" :closeSelectExamModal="closeSelectExamModal" />
            </div>

            <div v-if="selectResidanatExamModal">
              <SelectResidanatExam
                  :selectExamModal="selectResidanatExamModal"
                  :closeSelectExamModal="closeSelectResidanatExamModal" />
            </div>


            <AddModuleModal v-show="addModule" :closeAddModulePanel="closeAddModulePanel" />
            <AddCourseModal v-show="addCourse" :closeAddCoursePanel="closeAddCoursePanel" />
            <AddSubscriptioneModal v-show="addSubscription" :closeAddSubscriptionPanel="closeAddSubscriptionPanel" />

            
            <router-view class="w-full" :dark="mode" :SessionLogout="SessionLogout" :linkPath="linkPath" :openCreateSessionModal="openCreateSessionModal"></router-view>

          </div>
          <!-- <div :class="(mode && User.role=='etudiant') ? 'bg-gray-600 text-white': 'bg-gray-100 text-gray-700'" class=" text-xs  w-full text-center mt-2 font-mono">Created by <a href="https://dz.linkedin.com/in/madani-khoucha-480596170?trk=pub-pbmap" target="_blank">KHOUCHA.M</a> & <a href="https://dz.linkedin.com/in/mouloud-imad-meziani-a4b4961a5" target="_blank">MEZIANI.I</a></div> -->
        </div>      

      </main>
      <Settingbar :changeMode="changeMode" :dark="mode" :closeSettingsPanel="closeSettingsPanel" :settingPanel="settingPanel"/>
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'"
      class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

  </div>
</template>

<script>


document.onkeydown = function(e) {
  if (store.getters.get_user.role === 'etudiant') {
  if(event.keyCode == 123) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode == 'C'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode == 'X'.charCodeAt(0)) {
     return false;
  }
}
}
import Sidebar from "../tools/Sidebar.vue";
import Navbar from "../tools/Navbar.vue";
import Settingbar from "../tools/Settingbar.vue";
import CreateSessionModal from '../dashboard/SessionModals/CreateSessionModal.vue'
import CreateFreeSessionModal from '../dashboard/SessionModals/CreateFreeSessionModal.vue'
import AddPlaylistModal from '../dashboard/PlaylistModals/AddPlaylistModal.vue'
import SelectExam from '../dashboard/ExamModals/SelectExam.vue'
import AddModuleModal from '../dashboard/AddModule&Course/AddModuleModal.vue'
import AddCourseModal from '../dashboard/AddModule&Course/AddCourseModal.vue'
import AddSubscriptioneModal from '../dashboard/SubscriptionModals/AddSubscriptionModal.vue'
import { ElNotification  } from 'element-plus';
import router from "../../router/index.js";
import store from "../../store";
import SelectResidanatExam from '../dashboard/Residana/user/components/SelectResidanatExam.vue';

// document.oncontextmenu = function () {
//   if (store.getters.get_user.role === 'admin'){
//     return true
//   }else{
//     return false
//   }
// }


export default {
  components: {
    Sidebar,
    Navbar,
    Settingbar,
    CreateSessionModal,
    CreateFreeSessionModal,
    AddPlaylistModal,
    SelectExam,
    AddModuleModal,
    AddCourseModal,
    AddSubscriptioneModal,
    SelectResidanatExam
  },
  data() {
    return {
      isLoading: false,
      settingPanel: false,
      addModule: false,
      addCourse: false,
      addSubscription: false,

      title: null,

      SessionLogout: false,
      createSessionDialogVisible : false,
      createSessionFreeDialogVisible:false,
      
      createPlaylistDialogVisible:false,
      selectExamModal: false,
      selectResidanatExamModal: false
    };
  },
  mounted () {
    if(this.$store.getters.get_user.year === null && this.$store.getters.get_user.role === "etudiant"){
      this.YearNotification();
    }

    /*if (this.$store.getters.get_user.role === "etudiant"){
      axios
        .get(process.env.VUE_APP_API_BASE_URL+"/auth/user-profile", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          
        })
        .catch(function (error) {
          if (error.response.status == 401) {
            store.commit('set_token', null)
            store.commit('set_user', null)
            store.commit('set_islogin', false)
            store.commit('set_image', null)
            store.commit('set_darkMode', false)
            store.commit('set_subscriptions', [])
            store.commit('set_subscribeNow', null)
            router.push("/login")
          }
        });
    }*/

  },
  methods: {
    changeMode(action) {
      this.$store.commit("set_darkMode", action);
    },

    // Check(){
    //   console.log(this.User.role);
    //   
    // },

    closeSettingsPanel() {
      this.settingPanel = false;
    },

    openSettingsPanel() {
      this.settingPanel = true;
    },

    openAddModulePanel() {
      this.addModule = true;
    },

    openAddSubscriptionPanel(){
      this.addSubscription = true;
    },

    openAddCoursePanel() {
      this.addCourse = true;
    },

    closeAddSubscriptionPanel(){
      this.addSubscription = false;
    },

    closeAddModulePanel() {
      this.addModule = false;
    },

    closeAddCoursePanel() {
      this.addCourse = false;
    },

    startLoading() {
      this.isLoading = true;
    },

    endLoading() {
      this.isLoading = true;
    },

    openCreateSessionModal(){
      if(this.Subscribed){
        this.createSessionDialogVisible = true;
      }else{
        this.createSessionFreeDialogVisible = true;
      }
    },

    closeCreateSessionModal(){
      this.createSessionDialogVisible = false;
    },

    closeCreateFreeSessionModal(){
      this.createSessionFreeDialogVisible = false;
    },
    openCreatePlaylistModal(){
      this.createPlaylistDialogVisible = true;
    },

    closeCreatePlaylistModal(){
      this.createPlaylistDialogVisible = false;
    },


    openSelectExamModal(){
      this.selectExamModal = true;
    },
    openSelectResidanatExamModal(){
      this.selectResidanatExamModal = true;
    },

    closeSelectExamModal(){
      this.selectExamModal = false;
    },
    closeSelectResidanatExamModal(){
      this.selectResidanatExamModal = false;
    },
   

    linkPath(path){
        if(path == "/dashboard"){
          this.title = "Tableau de bord"
        }else if(path == "/dashboard/questions"){
          this.title = "Questions"
        }else if(path == "/dashboard/addquestions"){
          this.title = "Ajouter des questions"
        }else if(path == "/dashboard/exams"){
          this.title = "Sujets"
        }else if(path == "/dashboard/students"){
          this.title = "Étudiants"
        }else if(path == "/dashboard/account"){
          this.title = "Compte"
        }else if(path == "/dashboard/sessionsList"){
          this.title = "Liste des séries"
        }else if(path == "/dashboard/examensList"){
          this.title = "Liste des examens"
        }else if(path == "/dashboard/currentSession/?id=\\d+"){
          this.title = "Session en cours"
        }else if(path == "/dashboard/addquestionsform"){
          this.title = "Ajouter une question"
        }else if(path == "/dashboard/reportList"){
          this.title = "Liste de signalements"
        }else if(path == "/dashboard/playlistsList"){
          this.title = "Playlists"
        }else if(path == "/dashboard/subscriptions"){
          this.title = "Abonnements"
        }else if(path == "/dashboard/subscriptions"){
          this.title = "Abonnements"
        }else if(path == "/dashboard/residanat"){
          this.title = "Annales Résidanat"
        }else if( path == "/dashboard/residanat/examensList"){
          this.title = "Annales Résidanat"
        }else if(path == 'students/view/:id'){
          this.title = "Informations de l'utilisateur";
        }else if(path == "/dashboard/notifications") {
          this.title = "Notifications";
        }else if(path == "/dashboard/notifications/:id") {
          this.title = "Détails de notification";
        }
        else{
          this.title = path
        }
    },


    logout() {
      if(this.$route.name === 'CurrentSession'){
        this.SessionLogout = true
        this.$router.push("/currentSession");
      }else{
        this.startLoading();
        this.$store.dispatch("logoutAction").then(() => {
        this.$router.push("/login");
        this.endLoading();
        this.$store.commit('set_user', null);
      }).catch(() => {
         this.endLoading();
      });
      }
    },

    openCreateNewSession(){
      this.createNewSession = true;
    },

    closeCreateNewSession(){
      this.createNewSession = false;
    }

  },
  computed: {
    User() {
      return this.$store.getters.get_user;
    },
     isToken() {
      return this.$store.getters.get_token;
    },
    clicked(){
       return this.$store.getters.get_clicked;
    },
    mode(){
       return this.$store.getters.get_darkMode;
    },
    Subscribed(){
          return this.$store.getters.get_subscriptions.includes(String(this.$store.getters.get_subscribeNow))
    },
    SubscribeNow() {
      return this.$store.getters.get_subscribeNow
    },
    Subscribemodules() {
      return this.$store.getters.get_moduleSubscribed ;
    },
  },
  setup(){
    
    const YearNotification = () => {
      ElNotification({
        title: 'Completez votre inscription',
        message: "Veuillez cliquer ici pour sélectionner votre niveau d'étude",
        position: 'bottom-right',
        onClick:() => {router.push("/dashboard/account"); ElNotification.closeAll()},
        duration:0,
        type: 'warning',
      })
    };
    return{YearNotification}
  }
};
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>